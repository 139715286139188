import request from "./http.js";
let apiFun = {}; //设置一个对象暴露出去

// apiFun.api1 = () => request("get", "/api1"); // 仅地址
// apiFun.api2 = (参数) => request("post", "url", 参数); // 地址和传参
// apiFun.api3 = (params) => request("post", `url/${params}`); //携带在url中的参数
// apiFun.api4 = (params) => request("post", `url/${params.id}`, params); //携带在url中的参数和请求体参数
// apiFun.api5 = (id, params) => request("post", `url/${id}`, params); //根据项目情况，自己试一下就理解了

// apiFun.getCompanyFeature = () => request("get", '/cms/companyFeature/list'); // 获取公司特色或优势介绍
apiFun.getIndex = () => request("get", "/addons/litestore/api.index/index");
apiFun.getArea = (province,city) => request("get", `/addons/litestore/api.user/area?province=${province?province:''}${city?`&city=${city}`:''}`);
apiFun.getProvince = () => request("get", `/addons/litestore/api.user/area`);

apiFun.getSaleTopList = () => request("get", "/addons/litestore/api.user/login");
apiFun.categoryList = () => request("get", "/addons/litestore/api.category/showlist");
apiFun.categoryGoodsList = (params) => request("get", "/addons/litestore/api.goods/category_list", params);
apiFun.goodsDetail = (params) => request("get", "/addons/litestore/api.goods/detail", params);
apiFun.goodsAddCart = (params) => request("post", "/addons/litestore/api.cart/add", params);
apiFun.goodsSubCart = (params) => request("post", "/addons/litestore/api.cart/sub", params);
apiFun.goodsDelCart = (params) => request("post", "/addons/litestore/api.cart/delete", params);
apiFun.goodsBuyNow = () => request("get", "/addons/litestore/api.order/buyNow");
apiFun.accountLogin = (params) => request("post", "/addons/litestore/api.user/login", params);
apiFun.getCartTotalNum = () => request("get", "/addons/litestore/api.cart/getTotalNum");
apiFun.getCartList = () => request("get", "/addons/litestore/api.cart/getlists");
apiFun.getMyInfo = () => request("get", "/api/user/info");
apiFun.logout = () => request("post", "/api/user/logout");
apiFun.getArticleByTag = (params) => request("get", "/addons/litestore/api.article/getByTag", params);
apiFun.getArticleById = (params) => request("get", "/addons/litestore/api.article/getById", params);

apiFun.accountRegister = (params) => request("post", "/addons/litestore/api.user/register", params);
apiFun.setBuyNow = (params) => request("get", "/addons/litestore/api.order/setBuyNow", params);
apiFun.BuyNowPay = (params) => request("get", "/addons/litestore/api.order/buyNow_pay", params);
apiFun.getPersonalData = () => request("get", "/addons/litestore/api.user/get");
apiFun.editPersonalData = (params) => request("post", "/addons/litestore/api.user/edit", params);
apiFun.selectCart = (params) => request("post", "/addons/litestore/api.cart/select",params);
apiFun.checkoutCart = () => request("get", "/addons/litestore/api.cart/checkout");
apiFun.cartPay = (params) => request("post", "/addons/litestore/api.order/cart_pay", params);
apiFun.getOrderList = (params) => request("get", "/addons/litestore/api.order/my",params);
apiFun.cancelOrder = (params) => request("post", "/addons/litestore/api.order/cancel", params);
apiFun.payOrder = (params) => request("post", "/addons/litestore/api.order/order_pay", params);
apiFun.receiptOrder = (params) => request("post", "/addons/litestore/api.order/finish", params);
apiFun.setAddress = (params) => request("post", "/addons/litestore/api.user/set_address", params);
apiFun.unload = (params) => request("post", "/api/common/upload", params);
apiFun.searchList = (keyword,order,publisher) => request("get", `/addons/litestore/api.index/search?keyword=${keyword}&order=${order}&publisher_name=${publisher}`);
apiFun.getPublisher = () => request("get", "/addons/litestore/api.index/getpublisher");
apiFun.sendPasswordEmail = (params) => request("post", "/addons/litestore/api.user/sendPasswordEmail", params);
apiFun.checkRurl = (params) => request("post", "/addons/litestore/api.user/checkRurl", params);
apiFun.resetPasswrod = (params) => request("post", "/addons/litestore/api.user/resetPasswrod", params);
apiFun.xykZf = (url,params) => request("post", url, params);
apiFun.orderCheckout = (params) => request("get", "/addons/litestore/api.order/checkout", params);
apiFun.orderPay = (params) => request("post", "/addons/litestore/api.order/order_pay", params);
//暴露对象
export default apiFun;
